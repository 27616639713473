@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    font-family: Poppins, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@keyframes fadeIn {
    from {
        opacity: 1;
        transform: translateY(-30px);
    }
    to {
        opacity: 3;
        transform: translateY(0);
    }
}

.sidebar-enter {
    animation: fadeIn 0.3s ease-out;
}